import { IComponentProperty, componentProperties } from './component-properties'
import { IComponentType, componentTypes } from './component-types'

export interface IComponentTypeProperty {
  id: string;
  name: string;
  description: string | null;
  icon: string | null;
  group: string;
  vueComponent: string;
  properties: IComponentProperty[];
}

const buildComponentTypeProperties = (types: IComponentType[], properties: IComponentProperty[]) => {
  const result: IComponentTypeProperty[] = []

  for (const type of types) {
    result.push({
      id: type.id,
      name: type.name,
      description: type.description,
      icon: type.icon,
      vueComponent: type.vueComponent,
      group: type.group,
      properties: properties.filter(property => type.properties.includes(property.name))
    })
  }

  return result
}

export const componentTypeProperties: IComponentTypeProperty[] = buildComponentTypeProperties(componentTypes, componentProperties)
